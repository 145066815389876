import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stepper,
  StepConnector,
  StepLabel,
  Step,
  Zoom,
  Slide,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import { connect } from "react-redux";
import Loader from "src/components/Loading/Loading";
import SnackBar from "src/components/SnackBar/SnackBar";
import CryptoJS from "crypto-js";
import { serviceOrderService } from "src/_services/serviceOrder";
const PREFIX = "CustomComponent";

const classes = {
  root: `${PREFIX}-root`,
  boxcontainer: `${PREFIX}-boxcontainer`,
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  description: `${PREFIX}-description`,
  button: `${PREFIX}-button`,
  header: `${PREFIX}-header`,
  submitBtn: `${PREFIX}-submitBtn`,
  bottom: `${PREFIX}-bottom`,
  stepDescription: `${PREFIX}-stepDescription`,
  stepTitle: `${PREFIX}-stepTitle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    background: "#0D0D0D",
    flexGrow: 1,
    zIndex: 99,
    position: "absolute",
    marginBottom: "5rem",
  },
  [`& .${classes.boxcontainer}`]: {
    background: "#0D0D0D",
    padding: "16px 0",
    minHeight: "100vh",
  },
  [`& .${classes.container}`]: {
    backgroundColor: "#0D0D0D",
    padding: "1rem",
    display: "flex",
  },
  [`& .${classes.icon}`]: {
    fill: "#fff ",
    cursor: "pointer",
  },
  [`& .${classes.text}`]: {
    lineHeight: "18px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "20px",
    fontFamily: "Poppins ",
  },
  [`& .${classes.description}`]: {
    lineHeight: "20px",
    fontSize: "14px",
    color: "#fff",
    marginLeft: "20px",
    fontFamily: "Poppins ",
  },
  [`& .${classes.button}`]: {
    float: "right",
    marginTop: "-1rem",
  },
  [`& .${classes.header}`]: {
    fontFamily: "Poppins ",
    fontWeight: "bold ",
    fontSize: "16px ",
    color: "#fff",
    marginLeft: "20px",
  },
  [`& .${classes.submitBtn}`]: {
    display: "flex",
    justifyContent: "center ",
    width: "calc(100vw - 36px)",
    height: "54px",
    borderRadius: "10px ",
    padding: "12px 0px",
    boxShadow: "none ",
    "&:hover": {
      backgroundColor: " #6D50CC ",
      boxShadow: "none ",
    },
    textTransform: "uppercase ",
    fontFamily: "Poppins ",
    fontWeight: "500 ",
    fontSize: "16px ",
    color: "#fff",
  },
  [`& .${classes.bottom}`]: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  [`& .${classes.stepDescription}`]: {
    float: "left",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins ",
    fontWeight: "500 ",
    fontSize: "14px ",
    color: "#fff",
  },
  [`& .${classes.stepTitle}`]: {
    float: "right",
    display: "flex",
    alignItems: "center",
  },
}));



const QontoConnector = styled(StepConnector)(({ theme }) => ({
  alternativeLabel: {
    float: "right",
    display: "flex",
    alignItems: "center",
    left: "calc(-56% + 16px)",
    right: "calc(56% + 16px)",
  },
  "&.Mui-active .MuiStepConnector-line": {
    marginLeft: "-0.1rem",
    marginTop: "-3px",
  },
  "&.Mui-completed .MuiStepConnector-line": {
    borderLeft: "3px solid #7BDE89",
    marginLeft: "-1rem",
    marginTop: "-3px",
  },
  ".MuiStepConnector-line": {
    float: "right",
    display: "flex",
    marginTop: "-3px",
    alignItems: "center",
    marginRight: "1rem",
    borderLeft: "3px solid #323333",
    borderRadius: 1,
    minHeight: "3rem",
  },
}));

const NseStatus = (props) => {
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isLoading, serIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [serviceOrderData, setServiceOrderData] = useState();
  const [steps, setSteps] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  let order_id = urlParams.get("order_id");
  function decryptData(encryptedText, key) {
    // Convert key to 128-bit (16 bytes) for AES decryption
    let keyBytes = CryptoJS.enc.Utf8.parse(key);

    // Decrypt using AES
    let decrypted = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted data to UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  let password = "ulksecretKey";

  useEffect(() => {
    let decrypted_order_id =
      order_id &&
      decryptData(order_id.replaceAll(" ", "+"), password).replace(
        "UNLSORDER",
        ""
      );
    getNSEOrderStatus(decrypted_order_id);
  }, []);

  const NSEStatus = [
    "INACTIVE",
    "ACTIVE",
    "PROCESSING",
    "STAGE_ONE_PROCESSING",
    "STAGE_ONE_PROCESSED",
    "STAGE_TWO_PROCESSING",
    "STAGE_TWO_PROCESSED",
    "COMPLETED",
    "CLOSED",
  ];

  const stepNameOffline = [
    "INACTIVE",
    "ACTIVE",
    "PROCESSING",
    "COMPLETED",
    "CLOSED",
  ];

  function getNSESteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Active", message: "Make it Active" },
      { label: "Processing", message: "Make it Processing" },
      { label: "Stage 1 Processing", message: "Make it Stage 1 Processing" },
      { label: "Stage 1 Processed", message: "Make it Stage 1 Processed" },
      { label: "Stage 2 Processing", message: "Make it Stage 2 Processing" },
      { label: "Stage 2 Processed", message: "Make it Stage 2 Processed" },
      { label: "Completed", message: "Make it Completed" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  function getOfflineSteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Active", message: "Make it Active" },
      { label: "Processing", message: "Make it Processing" },
      { label: "Completed", message: "Make it Completed" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  const getNSEOrderStatus = (id) => {
    try {
        serIsLoading(true);
        serviceOrderService.getServiceOrderStatusByID(id).then((res) => {
          serIsLoading(false);
          if (res.status ===200) {
            setServiceOrderData(res.data);
            let steps = res.data.is_nse_trade ? getNSESteps() : getOfflineSteps()
            steps.forEach((item, index) => {
              setTimeout(() => {
                setSteps((prevSteps) => {
                  if (!prevSteps.some((step) => step.label === item.label)) {
                    return [...prevSteps, item];
                  }
                  return prevSteps;
                });
              }, index * 200); // Adding delay incrementally
            });
            if (res.data.is_nse_trade) {
              let stepNameIndex = NSEStatus.indexOf(res.data.order_status_code) + 1;
              setActiveStep(stepNameIndex);
            } else {
              let stepNameIndex =
                stepNameOffline.indexOf(res.data.order_status_code) + 1;
              setActiveStep(stepNameIndex);
            }
          }else{
            setSnackbarTitle('Something went wrong')
            setSeverity('error')
            setOpenSnackBar(true)
          }
        })
    } catch (error) {
        
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
 
  const StepIconRoot = styled("div")({
    color: "#eaeaf0",
    display: "flex",
    height: 10,
    alignItems: "center",
  });
  
  const StepIconCircle = styled(Check)({
    borderRadius: "50%",
    color: "#fff",
    background: "#323333",
    padding: "2px",
    marginTop: "8px",
  });
  
  const StepIconCompleted = styled(Check)({
    background: "#7BDE89",
    borderRadius: "50%",
    padding: "2px",
    color: "#fff",
  });
  
  const StepIconActive = styled(Check)({
    background: "#323333",
    borderRadius: "50%",
    padding: "2px",
    color: "#AC93FF",
  });
  
  function QontoStepIcon({ active, completed }) {
    return (
      <StepIconRoot>
        {completed ? (
          <StepIconCompleted fontSize="small" />
        ) : active ? (
          <StepIconActive fontSize="small" />
        ) : (
          <StepIconCircle fontSize="small" />
        )}
      </StepIconRoot>
    );
  }

  return (
    <Root>
      <Container maxWidth="sm" className={classes.boxcontainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Grid container direction="row">
              <Typography className={classes.header}>
                 Order status
              </Typography>
            </Grid>
            <div>
              <Typography className={classes.text}>
                {" "}
                Hey <b>{serviceOrderData?.display_name}</b>,
              </Typography>
              <Typography className={classes.description}>
                {" "}
                Here is your order status for UNLSORDER{serviceOrderData?.id}
              </Typography>
              <Slide  direction="down" in={true}>
                <Stepper
                  style={{ padding: "50px 32px 0px 32px" }}
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                  orientation="vertical"
                >
                  {steps.map((label, index) => (
                    <Step
                      style={{
                        opacity: steps.length > index ? 1 : 0,
                        transform:
                          steps.length > index
                            ? "translateY(0px)"
                            : "translateY(10px)",
                        transition: `opacity 0.5s ease ${index *
                          0.2}s, transform 0.5s ease ${index * 0.5}s`,
                      }}
                      key={label.title}
                      className={classes.button}
                    >
                      <Zoom  in={true}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        className={classes.stepTitle}
                      ></StepLabel>
                      </Zoom>
                      <Zoom in={true}>
                        <Typography sx={{fontWeight:activeStep ===index ?'bold' : ''}} className={classes.stepDescription}>
                          {label.label}
                        </Typography>
                      </Zoom>
                    </Step>
                  ))}
                </Stepper>
              </Slide>
            </div>
          </>
        )}
      </Container>
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(NseStatus);
