import React, { useEffect, useState } from "react";
import { Typography, Container, Grid, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import Loader from "src/components/Loading/Loading";
import SnackBar from "src/components/SnackBar/SnackBar";
import { serviceOrderService } from "src/_services/serviceOrder";
import Page from "src/components/Page";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { useParams } from "react-router";
import Thanks from "./favicon.ico";
const PREFIX = "CustomComponent";

const classes = {
  root: `${PREFIX}-root`,
  boxcontainer: `${PREFIX}-boxcontainer`,
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  description: `${PREFIX}-description`,
  button: `${PREFIX}-button`,
  header: `${PREFIX}-header`,
  submitBtn: `${PREFIX}-submitBtn`,
  bottom: `${PREFIX}-bottom`,
  value: `${PREFIX}-value`,
  stepTitle: `${PREFIX}-stepTitle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    background: "#0D0D0D",
    flexGrow: 1,
    zIndex: 99,
    position: "absolute",
    marginBottom: "5rem",
  },
  [`& .${classes.boxcontainer}`]: {
    background: "#0D0D0D",
    padding: "16px 0",
    minHeight: "100vh",
  },
  [`& .${classes.container}`]: {
    backgroundColor: "#0D0D0D",
    padding: "1rem",
    display: "flex",
  },
  [`& .${classes.icon}`]: {
    fill: "#fff ",
    cursor: "pointer",
  },
  [`& .${classes.text}`]: {
    lineHeight: "18px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "20px",
    fontFamily: "Poppins ",
  },
  [`& .${classes.description}`]: {
    lineHeight: "30px",
    fontSize: "14px",
    color: "#fff",
    fontWeight: 500,
    textAlign: "left",
    marginLeft: "20px",
    fontFamily: "Poppins ",
  },

  [`& .${classes.button}`]: {
    float: "right",
    marginTop: "-1rem",
  },
  [`& .${classes.header}`]: {
    fontFamily: "Poppins ",
    fontWeight: "bold ",
    fontSize: "16px ",
    textAlign: "center",
    color: "#fff",
  },
  [`& .${classes.submitBtn}`]: {
    display: "flex",
    justifyContent: "center ",
    width: "200px",
    height: "54px",
    borderRadius: "10px ",
    padding: "12px 20px",
    boxShadow: "none ",
    backgroundColor: " #6D50CC ",
    "&:hover": {
      backgroundColor: " #6D50CC ",
      boxShadow: "none ",
    },
    textTransform: "none",
    fontFamily: "Poppins ",
    fontWeight: "500 ",
    fontSize: "16px ",
    color: "#fff",
  },
  [`& .${classes.bottom}`]: {
    position: "absolute",
    bottom: "117px",
    left: "50%",
    transform: "translate(-50%, -0%)",
  },
  [`& .${classes.value}`]: {
    float: "left",
    display: "flex",
    alignItem: "center",
    lineHeight: "30px",
    fontFamily: "Poppins ",
    fontSize: "14px ",
    color: "#cbcbcb",
  },
  [`& .${classes.stepTitle}`]: {
    float: "right",
    display: "flex",
    alignItems: "center",
  },
}));

const ApproveOrder = (props) => {
  let id = useParams();
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityData, setSecurityData] = useState([]);
  const [serviceOrderData, setServiceOrderData] = useState();

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const getSecurityData = async (id) => {
    setLoading(true);
    await serviceOrderService
      .getSecurityByID(id)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setSecurityData(
              res.data.sort((a, b) =>
                a.security_name.localeCompare(b.security_name)
              )
            );
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    await serviceOrderService.getServiceOrderByID(id).then(async (res) => {
      if (res) {
        setLoading(false);
        if (res.status === 200) {
          setServiceOrderData(res.data);
        }
      }
    });
  };
  let totalConsolidatedPrice =
    securityData.length > 0 &&
    securityData?.reduce((sum, item) => {
      return sum + Number(item.price) * Number(item.quantity);
    }, 0);

  let totalSoldPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(totalConsolidatedPrice);

  useEffect(() => {
    getSecurityData(id.id);
  }, []);

  const handleApprove = async () => {
    setLoading(true);
    const data = {
      order_id: id.id,
    };
    await serviceOrderService.approveServiceOrder(data).then((res) => {
      if (res) {
        setLoading(false);
        if (res.status === 200  || res.status === 204) {
          setSeverity("success");
          setSnackbarTitle("Order Approved Successfully");
          setOpenSnackBar(true);
          getSecurityData(id.id);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          } else {
            setSeverity("error");
            setSnackbarTitle("Failed to approve order");
            setOpenSnackBar(true);
          }
        }
      }
    });
  };

  const RenderInfo = ({ name, value }) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography className={classes.description}>{name}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Page padding={true}>
      <Root>
        <Container maxWidth="sm" className={classes.boxcontainer}>
          {loading ? (
            <Box mt={4}>
              <Loader />
            </Box>
          ) : (
            <>
              <Typography variant="h4" className={classes.header}>
                {serviceOrderData?.is_approved ? (
                  <Box mt={2}>
                    <img src={Thanks} width={50} alt="Thanks" />
                    <Typography mt={2} variant="h4" className={classes.header}>
                      Order has been Approved!
                    </Typography>
                  </Box>
                ) : (
                  "Approve Order"
                )}
              </Typography>
              <Grid container mt={4}>
                <RenderInfo
                  name={"Order Type"}
                  value={serviceOrderData?.order_type}
                />
                <RenderInfo
                  name={"Service Id"}
                  value={serviceOrderData?.service_id}
                />
                <RenderInfo
                  name={"Lead Owner"}
                  value={serviceOrderData?.lead_owner}
                />
                <RenderInfo
                  name={"Assignee"}
                  value={serviceOrderData?.assign}
                />
                <RenderInfo
                  name={"Client Name"}
                  value={
                    serviceOrderData?.order_type_code === "SELL"
                      ? serviceOrderData?.investor
                      : serviceOrderData?.vendor
                  }
                />
                <RenderInfo name={"Order Value"} value={totalSoldPrice} />
                {!serviceOrderData?.is_approved ? (
                  <Grid item xs={12} className={classes.bottom}>
                    <Button
                      variant="contained"
                      className={classes.submitBtn}
                      onClick={handleApprove}
                    >
                      Approve
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    className={classes.bottom}
                    sx={{ bottom: "317px !important" }}
                  >
                    <Typography variant="h4" className={classes.header}>
                      Thank You 😊
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Container>
        <SnackBar
          open={openSnackBar}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />
      </Root>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state,
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(ApproveOrder);
