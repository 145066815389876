import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import loadingSpinner from 'src/images/loading-spinner.gif'
import {
  TextField,
  Container,
  Autocomplete,

} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";



const PREFIX = 'SendDealtermEmail';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));


function GenerateAnnexure({data,formSubmit}) {

  const [loading, setLoading] = React.useState(false)


  return (
    <StyledContainer>
      <Formik
        initialValues={{
          user:[] 
        }}
        validationSchema={Yup.object().shape({
            user: Yup.string().required("Required")
        })}
        onSubmit={(values) => {
          setLoading(true)
          formSubmit(values)
        }}
      >
        {({
          errors,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
        <Autocomplete
          multiple
          id="client"
          // name="investorOrVendor"
          value={values.user}
          options={data}
          onChange={(e,value)=>{
            if(value){
                setFieldValue("user",value)
            }else{
                setFieldValue("user",[])
            }

          }}
          getOptionLabel={(option) => option.stackHolder || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={errors.user}
              error={Boolean(errors.user)}
              label={'Generate Annexure for'}
            />
          )}
        />
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Generate" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Generate </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>

    </StyledContainer>
  );
}

export default GenerateAnnexure;
