import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from "src/images/loading-spinner.gif";
import { Field } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";

const PREFIX = "MailTemplate";

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  inputMargin: `${PREFIX}-inputMargin`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "50px",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },
}));

function SendNseEmail({ formSubmit, data }) {
  const [loading, setLoading] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const startDate = new Date();
  const initialData = {
    from_date: moment(
      new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1)
    ).format("YYYY-MM-DD"),
    to_date: moment(new Date()).format("YYYY-MM-DD"),
    mail_type: "",
    tagValue: "",
    order_type: "1",
    subject: "Order Status",
    emailTo:data?.email ? 'client' : '',
    customEmail:'',
    email_template:
      '<div><p>Hi <b>{name}</b>,</p><p>&nbsp;</p><p>Greetings!</p><p>&nbsp;</p><p>We would like to update you on the status of your order <b>{order_id}, </b><a href="{link}" style="color: #007bff; text-decoration: none;">click here</a> to track your order.</p><p>&nbsp;</p><p>For any further assistance, feel free to reach out to us on <a href="mailto:operations@unlistedkart.com">operations@unlistedkart.com</a></p><p>&nbsp;</p><p>Regards,</p><p><strong>Unlistedkart</strong></p></div>',
  };

  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagValue", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={Yup.object().shape({
          from_date: Yup.string().required("Required"),
          to_date: Yup.string().required("Required"),
          subject: Yup.string().required("Required"),
          email_template: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          let orderId = [];
          if (values.tagValue?.includes(" ")) {
            let orderArray = values.tagValue
              .split(" ")
              .map((item) => item?.trim().replace(",", ""));
            orderId = [...tags, ...orderArray];
          } else {
            orderId = [...tags, values.tagValue];
          }
          if (data) {
            orderId = [JSON.stringify(data.id)]
          }
          const orders = orderId
            .map((order) => order?.replace(/\D/g, ""))
            .map((order) => Number(order));
          const filteredArr = orders.filter(
            (item) => item !== "" && item !== 0
          );
          const payloadData = {
            order_id: filteredArr,
            ...values,
            email:values.emailTo  ==='client' ? data.email : values.customEmail
          };
          formSubmit(payloadData);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          handleBlur,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                {data ?   <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" fullWidth >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Send email to*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="role"
                      className={classes.inputMargin}
                      label="Send email to*"
                      // select
                      // SelectProps={{ native: true }}
                      variant="outlined"
                      // error={Boolean(touched.emailTo && errors.emailTo)}
                      // helperText={touched.emailTo && errors.emailTo}
                      name="emailTo"
                      value={values.emailTo}
                      aria-describedby="outlined-weight-helper-text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="off"
                    >
                      {data.email && <MenuItem value="client">{data.email}</MenuItem>}
                      {data?.transaction_type_code === "INTERMEDIATE" && <MenuItem value="channelPartner">{data?.channel_partner_email}</MenuItem>}
                      <MenuItem value="custom">New</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#F44336", position: "absolute", top: 53 }}>
                      {errors.emailTo && touched.emailTo && errors.emailTo}
                    </FormHelperText>
                  </FormControl>
                  {values.emailTo === 'custom' ? <TextField
                    error={Boolean(touched.customEmail && errors.customEmail)}
                    helperText={touched.customEmail && errors.customEmail}
                    name="customEmail"
                    label="Email Id*"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    value={values.customEmail}
                    className={classes.inputMargin}
                    variant="outlined"
                  /> : null}
                </Grid> : <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="from_date"
                      label="From Date"
                      type="date"
                      name="from_date"
                      error={Boolean(touched.from_date && errors.from_date)}
                      helperText={touched.from_date && errors.from_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputProps: {
                          max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                        },
                      }}
                      value={values.from_date}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="to_date"
                      label="End Date"
                      type="date"
                      name="to_date"
                      value={values.to_date}
                      InputProps={{
                        inputProps: {
                          min: values.from_date,
                          max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                        },
                      }}
                      error={Boolean(touched.to_date && errors.to_date)}
                      helperText={touched.to_date && errors.to_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} className={classes.gridPadding}>
                    <FormControl
                      component="fieldset"
                      error={Boolean(touched.order_type && errors.order_type)}
                    >
                      <FormLabel component="legend">Order Type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="order_type"
                        name="order_type"
                        value={values.order_type}
                        id="order_type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={"1"}
                          control={<Radio color="primary" />}
                          label="All"
                        />
                        <FormControlLabel
                          value={"2"}
                          control={<Radio color="primary" />}
                          label="NSE"
                        />
                        <FormControlLabel
                          value={"3"}
                          control={<Radio color="primary" />}
                          label="Offline"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {touched.payment_mode && errors.payment_mode}
                      </FormHelperText>
                    </FormControl>
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} textAlign={"center"}>
                    <Typography>Or</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      helperText=""
                      label={`Search by order id`}
                      onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                      name="tagValue"
                      // disabled={orders}
                      onChange={handleChange}
                    />
                    <div className={classes.wrap}>
                      {tags &&
                        tags.map((item, index) => (
                          <Chip
                            // disabled={orders}
                            style={{ margin: "4px" }}
                            key={index}
                            tabIndex={-1}
                            label={item}
                            onDelete={() => handleDelete(index)}
                          />
                        ))}
                    </div>
                  </Grid>
                </>}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    error={Boolean(touched.subject && errors.subject)}
                    helperText={touched.subject && errors.subject}
                    name="subject"
                    label="Mail Subject*"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    value={values.subject}
                    className={classes.inputMargin}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>Email Content</Typography>
                  <Field
                    name="email_template"
                    render={({ field, form }) => {
                      return (
                        <CKEditor
                          editor={ClassicEditor}
                          data={field.value}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "blockQuote",
                              "numberedList",
                              "bulletedList",
                              "insertTable",
                              "|",
                              "undo",
                              "redo",
                            ],
                          }}
                          onChange={(_, editor) => {
                            form.setFieldValue("email_template", editor.getData());
                          }}
                        />
                      );
                    }}
                  />
                  <FormHelperText
                    style={{ color: errors.email_template && "#F44336" }}
                  >
                    {touched.email_template && errors.email_template}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Send"
                ) : (
                  <>
                    <img
                      alt="loader"
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />{" "}
                    Send{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default SendNseEmail;
