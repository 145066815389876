import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { Chip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import NormalCard from "src/components/Cards/NormalCard";
import SnackBar from "src/components/SnackBar/SnackBar";
import moment from "moment";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import GetMuiTheme from "src/components/theme";
import { serviceOrderService } from "src/_services/serviceOrder";
import Page from "src/components/Page";
import clsx from "clsx";
const PREFIX = "Index";

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  root: `${PREFIX}-root`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  iconbtn: `${PREFIX}-iconbtn`,
  inactive: `${PREFIX}-inactive`,
  closed: `${PREFIX}-closed`,
  completed: `${PREFIX}-completed`,
  processing: `${PREFIX}-processing`,
  preProcessing: `${PREFIX}-preProcessing`,
  cancelled: `${PREFIX}-cancelled`,
  hold: `${PREFIX}-hold`,
  pending: `${PREFIX}-pending`,
  active: `${PREFIX}-active`,
  stageOneProcessing: `${PREFIX}-stageOneProcessing`,
  stageOneProcessed: `${PREFIX}-stageOneProcessed`,
  stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
  stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tableEllips}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "11rem",
    },
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    padding: "24px",
  },

  [`& .${classes.popDownBtn}`]: {
    marginTop: "-11px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.65rem",
    },
    borderRadius: "4px",
    padding: "4px 8px",
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: "flex",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  },
  [`& .${classes.inactive}`]: {
    color: "white",
    backgroundColor: "#D50000",
    fontWeight: "bold",
  },

  [`& .${classes.closed}`]: {
    color: "white",
    backgroundColor: "#314b8f",
    fontWeight: "bold",
  },

  [`& .${classes.completed}`]: {
    color: "white",
    backgroundColor: "#285430",
    fontWeight: "bold",
  },

  [`& .${classes.processing}`]: {
    color: "white",
    backgroundColor: "#CD7A00",
    fontWeight: "bold",
  },

  [`& .${classes.preProcessing}`]: {
    color: "white",
    backgroundColor: "#FFA559",
    fontWeight: "bold",
  },

  [`& .${classes.cancelled}`]: {
    color: "white",
    backgroundColor: "#226FCD",
    fontWeight: "bold",
  },

  [`& .${classes.hold}`]: {
    color: "white",
    backgroundColor: "#FA9884",
    fontWeight: "bold",
  },

  [`& .${classes.pending}`]: {
    color: "white",
    backgroundColor: "grey",
    fontWeight: "bold",
  },

  [`& .${classes.active}`]: {
    color: "white",
    backgroundColor: "#a526a5",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessing}`]: {
    color: "white",
    backgroundColor: "#87C4FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessed}`]: {
    color: "white",
    backgroundColor: "#39A7FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessed}`]: {
    color: "white",
    backgroundColor: "#427D9D",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessing}`]: {
    color: "white",
    backgroundColor: "#9BBEC8",
    fontWeight: "bold",
  },
}));

const ApproveOrderList = (props) => {
  const [holiday, setHoliday] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const getHolidayList = () => {
    setLoading(true);
    serviceOrderService
      .getApprovedOrders()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setHoliday(res.data.data);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Something Went Wrong!");
              setSnackBarOpen(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getHolidayList();
  }, []);
  // eslint-disable-next-line

  const getMuiTheme = GetMuiTheme;

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_id",
      label: "Service Id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant="body2"
              onClick={() => {
                window.open(
                  `/app/service-order-details/${tableMeta.rowData[0]}`,
                  "_blank"
                );
              }}
              sx={{ cursor: "pointer" }}
              noWrap={false}
              // className={classes.formgroup}
              color="primary"
            >
              {tableMeta.rowData[8]
                ? tableMeta.rowData[14] !== null
                  ? tableMeta.rowData[14]
                  : value
                : value}
            </Typography>
          );
        },
      },
    },

    {
      name: "lead_owner",
      label: "Lead Owner",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "approved_by",
      label: "Approved By",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "approved_datetime",
      label: "Approved On",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {value ? moment(value).format("DD-MM-YYYY LTS") : ""}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "order_status",
      label: "Status",
      options: {
        hint: "Transaction Service Progress",
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography component={"span"}>
              <Chip
                size="small"
                label={value === "Preprocessing" ? "Pre-processing" : value}
                className={clsx({
                  [classes.active]: value === "Active",
                  [classes.completed]: value === "Completed",
                  [classes.inactive]:
                    value === "Inactive" || value === "INACTIVE",
                  [classes.processing]: value === "Processing",
                  [classes.preProcessing]: value === "Preprocessing",
                  [classes.cancelled]: value === "Cancelled",
                  [classes.hold]: value === "Onhold",
                  [classes.closed]: value === "Closed",
                  [classes.pending]: value === "Deal Term Pending",
                  [classes.shareTransferProcessing]:
                    value === "Share Transfer Pending",
                  [classes.stageOneProcessing]:
                    value === "Stage one processing",
                  [classes.stageOneProcessed]: value === "Stage one processed",
                  [classes.stageTwoProcessing]:
                    value === "Stage two processing",
                  [classes.stageTwoProcessed]: value === "Stage two processed",
                })}
              />
            </Typography>
          );
        },
      },
    },
  ];

  return (
    <Page>
      <Root>
        <NormalCard title={"Approved Orders"}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={holiday}
              headerSort={false}
              columns={columns}
              options={{
                download: false,

                print: false,
                filter: false,
                viewColumns: false,
                rowsPerPage: 10,
                rowsPerPageOptions: [5, 10, 20],
                // responsive: 'scrollMaxHeight',
                selectableRows: "none",
                sortOrder: {
                  name: "last_updated",
                  direction: "desc",
                },
                textLabels: {
                  body: {
                    noMatch: loading
                      ? "Loading..."
                      : "Sorry, there are no matching data to display",
                  },
                },
              }}
            />
          </ThemeProvider>
        </NormalCard>

        <SnackBar
          open={snackBarOpen}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />

        <SessionExpiredModal open={openSessionExpiredModal} />
      </Root>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(ApproveOrderList);
