import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Typography,
  IconButton,
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Tooltip
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import NormalCard from "src/components/Cards/NormalCard";
import moment from "moment";
import GetMuiTheme from "src/components/theme";
import 'react-json-view-lite/dist/index.css';
import { serviceOrderService } from "src/_services/serviceOrder";
import { Email } from "@mui/icons-material";
import SendIcon from '@mui/icons-material/Send';
import ModalCentered from "src/components/Modals/ModalCentered";

const OrderStatusEmailLogs = ({ id, title, setSendOrderStatus,refresh }) => {
  const [emailLogs, setEmailLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);

  const getMuiTheme = GetMuiTheme;
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  const getLogs = (id) => {
    setLoading(true);
    try {
      serviceOrderService.getOrderStatusEmailLogs(id)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              setEmailLogs(res.data);
              setLoading(false);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setLoading(false);
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) { }
  };

  const columns = [
    {
      name: "created_time",
      label: "Created date",
      options: {
        filter: false,
        sort: false,
        hide: true,
        customBodyRender: (value, rowData) => {
          const date = moment(value).format("DD-MM-YYYY LT");
          return (
            <Typography variant="body2" noWrap>
              {date}
            </Typography>
          );
        },
      },
    },
    {
      name: "email_id",
      label: "Email Id",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,
      }
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,

      },
    },
    {
      name: "body",
      label: "Mail Body",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              disableTouchRipple
              sx={{ padding: 0, ml: "8px", textAlign: "center" }}
              onClick={()=>
              {
                setValue(value)
                setOpen(true)
              }
              }
            >
              <Tooltip title='Click here'>
                <Email />
              </Tooltip>
            </IconButton>
          );
        },
      },

    },
    {
      name: "created_user_name",
      label: "Sent By",
      options: {
        filter: false,
        sort: false,
      },
    },

  ];

  useEffect(() => {
    getLogs(id);
  }, [refresh]);

  const handleModalCloseComplete = () => {
    setResendEmail(false);
  }


  return (
    <NormalCard title={title}
      btn={
        <Button variant="contained" size='small' onClick={() => {
          if (emailLogs.length > 0) {
            setResendEmail(true)
          } else {
            setSendOrderStatus(true)
          }
        }} sx={{ ml: 2, textTransform: 'none' }} endIcon={<SendIcon />}>
         {`${emailLogs.length > 0 ? 'Resend' : 'Send' } Email`}
        </Button>
      }>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={emailLogs}
          columns={columns}
          options={{
            download: true,
            print: false,
            rowsPerPage: 10,
            filter: false,
            viewColumns: false,
            jumpToPage: true,
            rowsPerPageOptions: [10, 25, 40],
            // responsive: 'scrollMaxHeight',
            selectableRows: "none",
            sortOrder: {
              name: "last_updated",
              direction: "desc",
            },
            textLabels: {
              body: {
                noMatch: loading
                  ? "Loading..."
                  : "Sorry, there are no matching data to display",
              },
            },
          }}
        />
      </ThemeProvider>
      <Dialog
        open={resendEmail}
        onClose={handleModalCloseComplete}
        toggle={handleModalCloseComplete}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography
            style={{
              backgroundColor: "#314B8F",
              padding: 12,
              color: "#fff",
              border: "1px solid #324c90",
              width: "max-content",
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "0 0  10px 0",
              marginTop: "-20px",
              marginLeft: "-25px",
            }}
          >
            Resend Email
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email has been already sent, Are you sure you want to resend order status email for this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSendOrderStatus(true)
              handleModalCloseComplete()
            }}
            color="primary"
            variant="contained"
            style={{ textTransform: "none", textAlign: "center", margin: 10 }}
          >
            Yes
          </Button>
          <Button
            onClick={handleModalCloseComplete}
            color="primary"
            variant="contained"
            style={{ textTransform: "none", textAlign: "center" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
   <ModalCentered
          title={"Mail Body"}
          open={open}
          toggle={()=>setOpen(false)}
          children={
            <Typography>
              <div dangerouslySetInnerHTML={createMarkup(value)} />
            </Typography>
          }
          />
    </NormalCard>
  );
};

export default OrderStatusEmailLogs;
