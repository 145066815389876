/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Logo from "src/components/Logo";
import { connect } from "react-redux";
import { logoutSuccess } from "src/store/bugs";
import { useNavigate, useLocation } from "react-router-dom";
// import IdelTimerContainer from "src/components/IdelTimer/IdelTimeModal";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";
import { serviceOrderService } from "src/_services/serviceOrder";
const PREFIX = "TopBar";

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  logoName: `${PREFIX}-logoName`,
  logoHome: `${PREFIX}-logoHome`,
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
  },

  [`& .${classes.logoName}`]: {
    display: "flex",
    alignItem: "center",
    color: "#fff",
  },

  [`& .${classes.logoHome}`]: {
    color: "#fff",
  },
}));

const TopBar = ({ className, onMobileNavOpen, logoutSuccess, ...rest }) => {
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState({
    pendingCount: 0,
    totalCount: 0,
  });
  const logout = () => {
    logoutSuccess();
    navigate("/login", { replace: true });
  };
  let path = location.pathname;

  const approvedCount = localStorage.getItem("approvedCount") || 0;

  useEffect(() => {
    const getApprovedApplications = async () => {
      try {
        const response = await serviceOrderService.getApprovedOrders();
        if (response?.status === 200) {
          setCount({
            pendingCount: response.data.count
              ? response.data.count - Number(approvedCount)
              : 0,
            totalCount: response.data.count,
          });
        }
      } catch (error) {
        console.error("Error fetching approved applications:", error);
      }
    };
    getApprovedApplications();
  }, [path]);

  return (
    <StyledAppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        {" "}
        <div className={classes.logoName}>
          <Logo />
          <Typography variant="h4" style={{ margin: "auto", marginLeft: 10 }}>
            UnlistedKart
          </Typography>
        </div>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={() => {
              navigate("/app/approved-orders", { replace: true });
              localStorage.setItem("approvedCount", count.totalCount);
            }}
          >
            <Badge badgeContent={count.pendingCount} color="success">
              <Tooltip title="Approved Orders" arrow placement="left">
                <NotificationsIcon />
              </Tooltip>
            </Badge>
          </IconButton>
        </Hidden>
        <IconButton
          color="inherit"
          onClick={() => navigate("/app/dashboard", { replace: true })}
        >
          <Tooltip title="Home" arrow placement="left">
            <HomeIcon />
          </Tooltip>
        </IconButton>
        <IconButton color="inherit" onClick={() => logout()}>
          <Tooltip title="Logout" arrow placement="left">
            <ExitToAppIcon />
          </Tooltip>
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <Tooltip title="Menu" arrow placement="left">
              <MenuIcon />
            </Tooltip>
          </IconButton>
        </Hidden>
      </Toolbar>
    </StyledAppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(null, dispatchToProps)(TopBar);
