import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";

const PREFIX = 'OrderExportForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

const OrderExportForm = (props) => {

  const [loading, setLoading] = React.useState(false);

  const initValues = {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    orderType: "all"
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.orderType && errors.orderType)}
                  >
                    <FormLabel component="legend">Order type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="orderType"
                      value={values.orderType}
                      id="orderType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {/* <FormControlLabel value="1" control={<Radio color="primary" />} label="Micro" /> */}
                      {props.type === "MIS" &&
                      values.userType === "online" ? null : (
                        <FormControlLabel
                          value="all"
                          control={<Radio color="primary" />}
                          label="All"
                        />
                      )}
                      {props.type === "MIS" &&
                      values.userType === "online" ? null : (
                        <FormControlLabel
                          value="buy"
                          control={<Radio color="primary" />}
                          label="Buy"
                        />
                      )}
                      <FormControlLabel
                        value="sell"
                        control={<Radio color="primary" />}
                        label="Sell"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="fromDate"
                    label="From Date"
                    type="date"
                    name="fromDate"
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    value={values.fromDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="toDate"
                    label="End Date"
                    type="date"
                    name="toDate"
                    value={values.toDate}
                    InputProps={{
                      inputProps: {
                        min: values.fromDate,
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    error={Boolean(touched.toDate && errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
             
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {loading === false ? (
                      <> {"Download"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        Download{" "}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default OrderExportForm;
