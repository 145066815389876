import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  OutlinedInput,
  FormHelperText,
  Container,
  Typography,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";
import loadingSpinner from "src/images/loading-spinner.gif";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "src/containers/Profile/Rotate";

const PREFIX = "ApproveOrderByOps";

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paddingInput: `${PREFIX}-paddingInput`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },
}));

function ApproveOrderByOps(props) {
  let loading = props.loading;
  const [preview, setPreview] = useState();

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          remarks: "",
          file: "",
        }}
        validationSchema={Yup.object().shape({
          remarks: Yup.string().required("Required"),
          file: Yup.mixed()
          .nullable() // Allows the field to be empty
          .notRequired() // Makes it optional      
            .test(
              "fileFormat",
              "Unsupported Format",
              (value) =>  !value ||  SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
              "fileSize",
              "Maximum file size is 10MB",
              (value) =>  !value ||  value.size < 10 * 1024 * 1024
            ),
        })}
        onSubmit={(values) => {
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="remarks"
                    className={classes.inputMargin}
                    id="remarks"
                    multiline
                    minRows={2}
                    value={values.remarks}
                    label="Remarks*"
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.remarks && errors.remarks)}
                    helperText={touched.remarks && errors.remarks}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <div>
                <Typography className={classes.label}>Upload Proof</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="file"
                  name="file"
                  fullWidth={true}
                  onChange={(e) => {
                    setFieldValue("file", e.currentTarget.files[0]);
                  }}
                  type="file"
                  error={touched.file && Boolean(errors.file)}
                  helperText={touched.file ? errors.file : ""}
                />
                {values.file && !errors.file ? (
                  <FormHelperText
                    onClick={() => {
                      const url = URL.createObjectURL(values.file);
                      setPreview({
                        url: url,
                        type:
                          values.file.type === "application/pdf" ? true : false,
                      });
                    }}
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {"Preview"}
                  </FormHelperText>
                ) : (
                  <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                    {touched.file ? errors.file : ""}
                  </FormHelperText>
                )}
              </div>
              <ErrorFocus />

              <Button
                color="primary"
                disabled={loading || isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Upload"
                ) : (
                  <>
                    <img
                      alt="loader"
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />{" "}
                    Upload{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview?.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
}

export default ApproveOrderByOps;
