import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import Page from "src/components/Page";
import Checkbox from "@mui/material/Checkbox";
import ContactInfo from "src/components/Tables/ContactInfo";
import SnackBar from "src/components/SnackBar/SnackBar";
import { Formik, FieldArray, getIn } from "formik";
import { Grid, FormLabel,Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import Button from "@mui/material/Button";
import ModalCentered from "src/components/Modals/ModalCentered";
import NormalCard from "src/components/Cards/NormalCard";
// import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { userService } from "src/_services/users";
import { getLookUpDataService } from "src/_services/lookUp";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { useLocation,useNavigate } from "react-router-dom";
import Documents from "./Dcuments";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import { connect } from "react-redux";
import DematAccountData from "src/containers/Profile/DematAccount";
import loadingSpinner from "src/images/loading-spinner.gif";
import { OutlinedInput, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BankAccountData from "../Profile/BankAccount";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import KycDocumentData from "../Profile/KycDocument";
import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";
import DoneIcon from "@mui/icons-material/Done";
import ConfigurePartnerScrips from "./UserAccountDocument/ConfigurePartnerScrips";
import { serviceOrderService } from "src/_services/serviceOrder";
import compareJSON from "src/components/comparision";
const PREFIX = "CreateUsers";

const classes = {
  input: `${PREFIX}-input`,
  titleContainer: `${PREFIX}-titleContainer`,
  uploadAlign: `${PREFIX}-uploadAlign`,
  root: `${PREFIX}-root`,
  addressBlock: `${PREFIX}-addressBlock`,
  accountBlock: `${PREFIX}-accountBlock`,
  accountBtn: `${PREFIX}-accountBtn`,
  accountTitle: `${PREFIX}-accountTitle`,
  addressBlocktitle: `${PREFIX}-addressBlocktitle`,
  radioBlock: `${PREFIX}-radioBlock`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  btnroot: `${PREFIX}-btnroot`,
  addButton: `${PREFIX}-addButton`,
  removeLink: `${PREFIX}-removeLink`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: "none",
  },

  [`& .${classes.titleContainer}`]: {
    padding: "0 1rem",
  },

  [`& .${classes.uploadAlign}`]: {
    margin: "1rem 1rem 0 1rem",
  },

  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.addressBlock}`]: {
    padding: "1rem",
    color: "#314B8F",
  },

  [`& .${classes.accountBlock}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  [`& .${classes.accountBtn}`]: {
    borderRadius: "0 0 12px 12px",
  },

  [`& .${classes.accountTitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },

  [`& .${classes.addressBlocktitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    borderBottom: "1px solid #314B8F",
    padding: "10px 0",
    color: "#314B8F",
  },

  [`& .${classes.radioBlock}`]: {
    display: "block",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  [`& .${classes.addButton}`]: {
    fontSize: "13px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
      padding: "4px",
    },
  },

  [`& .${classes.removeLink}`]: {
    marginBottom: "1rem",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateUser = ({ authReducer }) => {
  const role = authReducer.auth
    ? authReducer.auth.user !== null && authReducer.auth.user.default_role_code
    : null;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [userRole, setUserRole] = React.useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [representatives, setRepresentative] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = useState(null);
  const [userID, setUserID] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const [valueColor, setValueColor] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [codeExists, setCodeExists] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [scripIds, setScripIds] = useState([]);
  const [partners, setPartners] = useState([]);
  const navigate = useNavigate()
  const [partnerCode, setParnetCode] = useState(false);
  const [userBasicData, setUserBasicData] = useState({
    selectrole: [],
    login: false,
    loginPhone: "",
    userType: "",
    firstName: "",
    lastname: "",
    email: "",
    phone: "",
    pan: "",
    stackHolderReport:[],
    ticket_access:false,
    status:'Pending',
    rejectReason:'',
    investor: false,
    requiredCoBranding: false,
    visibleAllScrips: true,
    requiredApi: false,
    apiKey: "token",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    partner: false,
    partnerClient: false,
    partnerClientCode: "",
    state:"",
    postcode: "",
    country: "",
    companyname: "",
    companyregistered: "",
    gstin: "",
    userName: "",
    password: "",
    endpoint: "",
    companypan: "",
    maxSellPercentage: "",
    partnerLandingPercentage: "",
    partnerLogo: null,
    partnerCode: "",
    priceupdate: false,
    prepaidApproved:false,
    prepaidApprovedRange:0,
    relation:'',
    relationName:'',
    internalrepresentative: [
      {
        id: authReducer.auth.user.user_unique_code,
        label: authReducer.auth.user.display_name,
      },
    ],
    authorisedPerson:'',
    businessNature:''
  });

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  let query = useQuery();
  let location = useLocation();
  const phoneRegex = RegExp(/^[0-9]{12}$/);

  const submitUserBasic = async (values) => {
    const formData = new FormData();
    formData.append("file", values.partnerLogo);
    if (values.partnerLogo) {
      await serviceOrderService.uploadBrandingImage(formData).then((res) => {
        if (res.status === 201) {
          CreateOrUpadateUser(values, res.data.url);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            CreateOrUpadateUser(values, "");
          }
        }
      });
    } else {
      CreateOrUpadateUser(values, "");
    }
  };

  const CreateOrUpadateUser = async (values, logoUrl) => {
    const result = compareJSON(values, userBasicData);
    const userRoles = values.selectrole.map((item) => item.code);
    const userAddress = {
      relation:values.relation ? values.relation :'',
      relationName:values.relationName ? values.relationName : '',
      address1: values.addresslineone === null ? "" : values.addresslineone,
      address2: values.addresslinetwo === null ? "" : values.addresslinetwo,
      city: values.city === null ? "" : values.city,
      state: values.state === null ? "" : values.state,
      country: values.country === null ? "" : values.country,
      zipcode: values.postcode === null ? "" : values.postcode,
    };
    const array = values.internalrepresentative?.map((item) => {
      return item.id;
    });
    let userData = {
      required_co_branding: values.requiredCoBranding,
      user_type: values.userType,
      first_name: values.firstName?.trim(),
      last_name: values.lastname?.trim(),
      email: values.email,
      contact_no: values.phone,
      pan: values.pan,
      required_login: values.login,
      required_price_update: values.priceupdate,
      gstin: values.gstin,
      user_role: userRoles,
      status:values.status ==='Verified' ?1 :values.status ==='Pending' ? 2 :3,
      reject_reason:values.rejectReason,
      visible_scrip_ids: scripIds
        ?.toString()
        .replace(/\[/g, "(")
        .replace(/]/g, ")"),
      is_all_scrip_visible: values.visibleAllScrips,
      stack_holders:JSON.stringify(values.stackHolderReport),
      username: values.loginPhone,
      login_mobile_no: values.loginPhone,
      company_name: values.companyname?.trim(),
      company_registration_no: values.companyregistered,
      reporting_user: values.internalrepresentative[0].id,
      additional_reporting_user: array,
      country_code: countryCode,
      partner_code: values.partnerClientCode
        ? values.partnerClientCode.id
        : values.partnerCode,
      partner_logo: logoUrl,
      partner_representative: values?.partnerClientCode
        ? values.partnerClientCode.userID
        : null,
      is_api_required: values.requiredApi,
      api_username: values.userName,
      api_password: values.password,
      authentication_method: values.apiKey === "token" ? 1 : 2,
      max_sell_percentage: values.maxSellPercentage,
      customer_landing_percentage: values.partnerLandingPercentage,
      prepaid_approved: values.prepaidApproved,
      prepaid_approved_range: values.prepaidApprovedRange,
      ticket_access:values.ticket_access,
      business_nature:values.businessNature,
      authorised_person:values.authorisedPerson,
      updatedValues: mode === "edit" ? JSON.stringify(result) : null,
    };

    if (values.loginPhone && values.loginPhone.charAt(0) !== "+") {
      userData.login_mobile_no = `+${values.loginPhone}`;
    }

    if (values.addresslineone !== "" && mode === "create") {
      userData.address = userAddress;
    }

    if (mode === "edit") {
      setSpinner(true);

      //update Address

      await userService.updateUserAddress(userID, userAddress).then((res) => {
        if (res) {
          if (res.status === 200) {
            setSpinner(false);
            setSeverity("success");
            setSnackbarTitle("User Address Updated");
            setOpenSnackBar(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setSpinner(false);
              setSeverity("error");
              setSnackbarTitle("Unable Update User Address");
              setOpenSnackBar(true);
            }
          }
        } else {
          setSpinner(false);
        }
      });
      await userService.updateUser(userID, userData).then((res) => {
        if (res) {
          if (res.status === 200) {
            setLoading(false);
            setSeverity("success");
            setSnackbarTitle("User basic Info Updated");
            setOpenSnackBar(true);
          } else if (res.status === 400) {
            setLoading(false);
            if (res.data === "MOBILE_NUMBER_ALREAY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("Mobile no already exist");
              setOpenSnackBar(true);
            } else if (res.data === "PAN_ALREADY_USED") {
              setSeverity("error");
              setSnackbarTitle("PAN already used");
              setOpenSnackBar(true);
            } else if (res.data === "COMPANEY_GSTIN_ALREADY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("GST already used");
              setOpenSnackBar(true);
            } else if (
              res.data === "COMPANEY_REGISTRATION_NUMBER_ALREADY_EXISTS"
            ) {
              setSeverity("error");
              setSnackbarTitle("Reg.No already used");
              setOpenSnackBar(true);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("OOPS something went wrong!");
                setOpenSnackBar(true);
              }
            }
          }
        } else {
          setLoading(false);
        }
      });
    } else if (mode === "create") {
      userData.username = `+${values.loginPhone}`;
      userData.login_mobile_no = `+${values.loginPhone}`;
      userService
        .createUser(userData)
        .then((res) => {
          if (res.status === 201) {
            setLoading(false);
            setUserID(res.data.id);
            setUserType(res.data.code)
            setSeverity("success");
            setSnackbarTitle("User Created Successfully");
            setOpenSnackBar(true);
            // setTimeout(() => {
            //   navigate('/app/user')
            // }, 1000)
          } else if (res.status === 400) {
            setLoading(false);
            if (res.data === "MOBILE_NUMBER_ALREAY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("Mobile no already exist");
              setOpenSnackBar(true);
            } else if (res.data === "PAN_ALREADY_USED") {
              setSeverity("error");
              setSnackbarTitle("PAN already used");
              setOpenSnackBar(true);
            } else if (res.data === "COMPANEY_GSTIN_ALREADY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("GST already used");
              setOpenSnackBar(true);
            } else if (
              res.data === "COMPANEY_REGISTRATION_NUMBER_ALREADY_EXISTS"
            ) {
              setSeverity("error");
              setSnackbarTitle("Reg.No already used");
              setOpenSnackBar(true);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("OOPS something went wrong!");
                setOpenSnackBar(true);
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
    }
  };

  const CheckPartnerCode = async (code) => {
    await userService.checkPartnerCode(code).then((res) => {
      if (res.data.success) {
        setCodeExists("old");
      } else {
        setCodeExists("new");
      }
    });
  };

  const generateApiKey = async () => {
    return await userService.getPartnerKey().then((res) => {
      if (res.data.message === "Success") {
        return res.data.data;
      } else {
        setSeverity("error");
        setSnackbarTitle("Something went wrong while generating the key");
        setOpenSnackBar(true);
      }
    });
  };

  function moveObjectToFront(array, key, value) {
    const index = array.findIndex((obj) => obj[key] === value);
    if (index > -1) {
      const [item] = array.splice(index, 1); // Remove the object from its current position
      array.unshift(item);
    }
    return array;
  }
  //trigger when mode is edit and user id is not null
  useEffect(() => {
    const getUserDetails = async () => {
      let partnerDetailObj = await getAllPartner();
      const Partners = partnerDetailObj.map((data) => {
        return {
          id: data.related_partner_code,
          label: data.name,
          userID: data.user_unique_code,
        };
      });
      setPartners(Partners);

      if (query.get("mode") === "edit" && userID != null) {
        setSpinner(true);
        let initValues = { ...userBasicData };
        await userService.getUserDetails(location.state.userID).then((res) => {
          if (res) {
            if (res.status === 200) {
              setSpinner(false);
              // if (res.data[0].code === "COMPANY") {
              //   setContactInfo("COMPANY");
              // }
              let obj = res.data[0].user_role.find(
                (o) => o.position_index === 6
              );
              if (obj) {
                initValues.partner = true;
              } else {
                initValues.partner = false;
              }
              setUserType(res.data[0].code);
              initValues.selectrole = res.data[0].user_role;
              initValues.status =res.data[0].status === 2 ? 'Pending':res.data[0].status === 1? "Verified":"Rejected"
              initValues.login = res.data[0].required_login;
              initValues.loginPhone =
                res.data[0].login_mobile_no &&
                res.data[0].login_mobile_no.substring(1);
              initValues.userType = res.data[0].code;
              initValues.firstName = res.data[0].first_name;
              initValues.lastname = res.data[0].last_name;
              initValues.email = res.data[0].email;
              initValues.phone = res.data[0].contact_no;
              initValues.pan = res.data[0].pan;
              initValues.companyname = res.data[0].company_name || "";
              initValues.companyregistered =
                res.data[0].company_registered_no || "";
              initValues.gstin = res.data[0].gstin || "";
              initValues.companypan = res.data[0].pan || "";
              initValues.rejectReason = res.data[0].rejected_reason || "";
              initValues.requiredCoBranding =
                res.data[0].required_co_branding || false;
              initValues.requiredApi = res.data[0].is_api_required;
              initValues.visibleAllScrips = res.data[0].is_visible_all_scrips;
              initValues.userName = res.data[0].api_username;
              initValues.password = res.data[0].api_password;
              initValues.ticket_access = res.data[0].ticket_access;
              initValues.maxSellPercentage = res.data[0].max_sell_percentage;
              initValues.prepaidApprovedRange = res.data[0].prepaid_approved_range;
              initValues.prepaidApproved = res.data[0].prepaid_approved;
              initValues.businessNature = res.data[0].business_nature;
              initValues.authorisedPerson = res.data[0].authorised_person;
              initValues.stackHolderReport =res.data[0].stack_holders ? JSON.parse(res.data[0].stack_holders) : []
              initValues.partnerLandingPercentage =
                res.data[0].customer_landing_percentage;
              initValues.apiKey =
                res.data[0].authentication_method === 1
                  ? "token"
                  : "credentials";
              setParnetCode(
                res.data[0].related_partner_code === "UNLK" ? false : true
              );
              initValues.partnerCode =
                res.data[0].related_partner_code === "UNLK"
                  ? ""
                  : res.data[0].related_partner_code;
              initValues.priceupdate = res.data[0].required_price_update;
              setImageUrl(
                res.data[0].related_partner_code === "UNLK"
                  ? null
                  : res.data[0].partner_logo
              );
              const code =
                Partners &&
                Partners.find((o) => {
                  return o.id === res.data[0].related_partner_code;
                });
              initValues.partnerClientCode = code;
              let newArray = [res.data[0].visible_scrip_ids];
              const visibleScrips = newArray[0]?.split(",");
              setScripIds(visibleScrips);
              if (res.data[0].reporting_user_code !== null) {
                initValues.internalrepresentative = res.data[0]
                  .additional_reporting_user
                  ? moveObjectToFront(res.data[0].additional_reporting_user,'id',res.data[0].reporting_user_code)
                  : [
                      {
                        id: res.data[0].reporting_user_code,
                        label: `${res.data[0].reporting_user_name}`,
                      },
                    ];
              }
              let Investor = res.data[0].user_role.find(
                (o) => o.position_index === 5
              );
              if (Investor) {
                initValues.investor = true;
              }
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              }
            }
          }
        });
        //get address and demat
        await userService.getUserAddress(userID).then((res) => {
          if (res) {
            if (res.status === 200 && res.data.length > 0) {
              initValues.relation = res.data[0].relation
                ? res.data[0].relation
                : '';
              initValues.relationName = res.data[0].relation_name
                ? res.data[0].relation_name
                : "";
              initValues.addresslineone = res.data[0].address1
                ? res.data[0].address1
                : "";
              initValues.addresslinetwo = res.data[0].address2
                ? res.data[0].address2
                : "";
              initValues.city = res.data[0].city ? res.data[0].city : "";
              initValues.state = res.data[0].state_code
                ? res.data[0].state_code
                : "";
              initValues.country = res.data[0].country_code
                ? res.data[0].country_code
                : "";
              initValues.postcode = res.data[0].zipcode
                ? res.data[0].zipcode
                : "";
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              }
            }
          }
        });
        setUserBasicData(initValues);
      }
    };
    getUserDetails();
  }, [userID]);
  //end trigger when mode is.....

  //get contact info from api
  

  function capitalizeFirstLetterForName(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }


  // lookup data api's
  useEffect(() => {
    setMode(query.get("mode") || null);
    // setUserID(location.state.userID || null)
    if (query.get("mode") === "edit") {
      setUserID(location.state?.userID || null);
      setUserName(location.state?.userName || null);
      setRequestId(location.state?.requestId || null);
    }
    getLookUpDataService.getRolesByUserRole().then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data) {
            // const data = res.data.filter((data) => {
            //   return data.code !== "PARTNER_CLIENT"
            // })
            setUserRole(res.data);
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });

    // getLookUpDataService.getLookUpData("user_type").then((res) => {
    //   if (res) {
    //     if (res.status === 200) {
    //       if (res.data) {
    //         setUserType(res.data[0].user_type);
    //       }
    //     } else {
    //       if (res.status === 401 && res.data.name === "TokenExpiredError") {
    //         setOpenSessionExpiredModal(true);
    //       }
    //     }
    //   }
    // });

    getLookUpDataService.getLookUpData("state").then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data) {
            setStates(res.data[0].state);
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });

    getLookUpDataService.getLookUpData("country").then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data) {
            setCountry(res.data[0].country);
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });

    getLookUpDataService.getLookUpUser("SALES").then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data) {
            setRepresentative(
              res.data.map((data) => {
                return { id: data.user_code, label: data.user_name };
              })
            );
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  }, []);

 
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };


const verifiedStatus =['Pending',"Verified","Rejected"]
  const status =  mode==='edit' ?verifiedStatus: verifiedStatus.slice(0, -1)
 
  // delete contact
 

  const handleError = (value) => {
    if (value?.length <= 2) {
      setValueColor(true);
    } else if (value?.length <= 10) {
      setValueColor(true);
    } else {
      setValueColor(false);
    }
  };

  const handlePartnerVisibleScrips = (value) => {
    setScripIds(value);
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
  ];

  const getAllPartner = async () => {
    setLoading(true);
    const res = await userService.getPartners();

    if (res) {
      if (res.status === 200) {
        setLoading(false);
        return res.data.message;
      } else {
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setLoading(false);
          setOpenSessionExpiredModal(true);
        }
      }
    }
  };

  return (
    <Root>
      <Page title="Unlistedkart | User Create">
       <Button
        sx={{marginBottom:'1rem',
          borderRadius: "14px 14px 0 0",
          boxShadow: "none",
          textTransform: "capitalize",
          fontSize: "0.9rem",
          lineHeight: "1.3",      
        }}
          onClick={()=>{
            navigate(`/app/user-details/${userID}`)
          }}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={!userID}
          className={classes.addButton}
          name="addDemat"
          value="addDemat"
        >
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={userBasicData}
          validationSchema={Yup.object().shape({
            selectrole: Yup.string().required("Required"),
            internalrepresentative: Yup.string().nullable(),

            userType: Yup.string().required("Required"),
            companyname: Yup.string().when("userType", {
              is: "COMPANY",
              then: Yup.string()
                .nullable()
                .required("Required"),
              otherwise: Yup.string(),
            }),
            companyregistered: Yup.string(),
            rejectReason:Yup.string().when("status",{
              is:"Rejected",
              then:Yup.string().required("Reject reason is required"),
              else:Yup.string().nullable()
            }),
            gstin: Yup.string().when("userType", {
              is: "COMPANY",
              then: Yup.string()
                .nullable()
                .matches(
                  "[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}",
                  "Incorrect format"
                ),
              otherwise: Yup.string(),
            }),
            pan: Yup.string()
              .max(10, "Invalid Pan Card")
              .nullable()
              .required("Pan is required")
              .matches("[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}", "Invalid PAN"),

            firstName: Yup.string().when("userType", {
              is: "INDIVIDUAL",
              then: Yup.string()
                .nullable()
                .required("First Name is required"),
              otherwise: Yup.string(),
            }),
            lastname: Yup.string(),
            relationName: Yup.string().when("relation", {
              is: (item) => item,
              then: Yup.string()
                .nullable()
                .required("Name is required"),
              otherwise: Yup.string(),
            }),
            email: Yup.string()
              .nullable()
              .email("Enter a valid email"),
              // .required("Email is required"),
            postcode: Yup.string()
              .nullable()
              .max(10, "Invalid Pin Code"),
            phone: Yup.string()
              .nullable(),
              // .required("Phone is required"),
            partnerLogo: Yup.mixed().when(["requiredCoBranding", "partner"], {
              is: (requiredCoBranding, partner) => {
                return (
                  partner && requiredCoBranding // Key-value pair exists in Field 1
                );
              },

              then:
                query.get("mode") !== "edit" &&
                Yup.mixed()
                  .test(
                    "fileFormat",
                    "Unsupported Format",
                    (value) =>
                      value === null ||
                      (value && SUPPORTED_FORMATS.includes(value.type))
                  )
                  .required("Logo is Required"),
              otherwise: Yup.mixed(),
            }),

            maxSellPercentage: Yup.number().when("partner", {
              is: true,
              then: Yup.number()
                .required("Required")
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
            partnerLandingPercentage: Yup.number().when("partner", {
              is: true,
              then: Yup.number()
                .required("Required")
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
            partnerClientCode: Yup.object().when(
              ["requiredCoBranding", "investor"],
              {
                is: (requiredCoBranding, investor) => {
                  return (
                    investor && requiredCoBranding // Key-value pair exists in Field 1
                  );
                },
                then: Yup.object()
                  .required("Required")
                  .nullable(),
                otherwise: Yup.object().nullable(),
              }
            ),
            partnerCode: Yup.string().when("partner", {
              is: true,
              then: Yup.string()
                .min(12, "Must be exactly 12 characters")
                .required("Partner code required"),
              otherwise: Yup.string().nullable(),
            }),
            prepaidApprovedRange:Yup.number().when("prepaidApproved", {
              is: true,
              then: Yup.number()
                .required("Required")
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
            apiKey: Yup.string().when("requiredApi", {
              is: true,
              then: Yup.string().required("Generate the key"),
              otherwise: Yup.string(),
            }),
            loginPhone: Yup.string().when("login", {
              is: true,
              then: Yup.string()
                .nullable()
                .required("Phone is required")
                .matches(phoneRegex, "Enter a valid Mobile Number"),
              otherwise: Yup.string().nullable(),
            }),
            stackHolderReport: Yup.array(
              Yup.object({
                designation: Yup.string().required('Designation required'),
                stackHolder: Yup.string().required('Stack holder name required'),
                stackHoldPercentage: Yup.number().when('designation', {
                  is: (designation) => designation !== 'Authorised Signatory',
                  then: (schema) => schema.required('Stack percentage required'),
                  otherwise: (schema) => schema.notRequired(),
                }),
                stackHolderPAN: Yup.string().when('designation', {
                  is: (designation) => designation !== 'Authorised Signatory',
                  then: (schema) =>
                    schema
                      .required('PAN required')
                      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, 'Invalid PAN'),
                  otherwise: (schema) => schema.notRequired(),
                }),
                relationName: Yup.string().when('relation', {
                  is:  (value)=> Boolean(value),
                  then: (schema) =>  schema.required('Name is required'),
                  otherwise: (schema) => schema.notRequired(),
                }),
                address: Yup.string().when('designation', {
                  is: (designation) => designation !== 'Authorised Signatory',
                  then: (schema) => schema.required('Address required'),
                  otherwise: (schema) => schema.notRequired(),
                }),
              })
            ),
          })}
          onSubmit={(values, actions) => {
            // values.preventDefault();
            setLoading(true);
            submitUserBasic(values);
            actions.setSubmitting(false);
            // actions.setTouched(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <NormalCard title="Basic Information">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div style={{ padding: "1rem" }}>
                  {role === "INVESTER_CLIENT" ? (
                    <></>
                  ) : (
                    <>
                      <Grid
                        container
                        spacing={5}
                        py={2}
                        alignItems="center"
                        // style={{ marginTop: "3px" }}
                      >
                        <Grid item xs={12} sm={6} md={4} py={2} lg={4}>
                          <Autocomplete
                            multiple
                            id="selectrole-role"
                            options={userRole.filter(function(data) {
                              return (
                                values.selectrole.filter(function(item) {
                                  return item.description === data.description;
                                }).length === 0
                              );
                            })}
                            getOptionLabel={(option) => option.description}
                            value={values.selectrole}
                            onChange={(e, value) => {
                              setFieldValue("selectrole", value);
                              let partner = value.find(
                                (o) => o.position_index === 6
                              );
                              let partnerClient = value.find(
                                (o) =>
                                  o.position_index === 7 ||
                                  o.position_index === 8
                              );
                              let opsTeam = value.find(
                                (o) =>
                                  o.position_index === 1 ||
                                  o.position_index === 2 ||
                                  o.position_index === 3
                              );
                              let Investor = value.find(
                                (o) => o.position_index === 5
                              );
                              if(opsTeam){
                                setFieldValue('status','Verified')
                                setFieldValue('ticket_access',values.ticket_access)
                              }else{
                                setFieldValue('status','Pending')
                                setFieldValue('ticket_access',false)
                              }
                              if (partner) {
                                setFieldValue("partner", true);
                              } else {
                                setFieldValue("partner", false);
                              }
                              if (Investor) {
                                setFieldValue("investor", true);
                              } else {
                                setFieldValue("investor", false);
                              }
                              if (partnerClient) {
                                setFieldValue("partnerClient", true);
                              } else {
                                setFieldValue("partnerClient", false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.selectrole && errors.selectrole
                                )}
                                helperText={
                                  touched.selectrole && errors.selectrole
                                }
                                name="selectrole"
                                label="Select User Type (Role)*"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                          {/* <ErrorFocus /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} py={2} lg={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="login"
                                checked={values.login}
                                color="primary"
                              />
                            }
                            onChange={handleChange}
                            label="Required login"
                            name="login"
                            id="login-mob"
                            value={values.login}
                          />
                         {values.selectrole.some(i => /ADMIN|SALES|OPERATION/.test(i.code)) ?  <FormControlLabel
                            control={
                              <Checkbox
                                name="priceupdate"
                                checked={values.priceupdate}
                                color="primary"
                              />
                            }
                            onChange={handleChange}
                            label="Required price update"
                            name="priceupdate"
                            id="price-update"
                            value={values.priceupdate}
                          /> : null}
                          {values.selectrole.some(
                            (i) =>
                              i.code.includes("INVESTER_CLIENT") ||
                              i.code.includes("PARTNER_ADMIN")
                          ) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="requiredCoBranding"
                                  checked={values.requiredCoBranding}
                                  color="primary"
                                />
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    "requiredCoBranding",
                                    e.target.checked
                                  );
                                } else {
                                  setFieldValue("partnerClientCode", "");
                                  setFieldValue(
                                    "requiredCoBranding",
                                    e.target.checked
                                  );
                                  setFieldValue("partnerCode", "");
                                }
                              }}
                              label="Required Co Branding"
                              name="requiredCoBranding"
                              id="price-update"
                              value={values.requiredCoBranding}
                            />
                          )}
                            {values.selectrole.some(
                            (i) =>
                              i.code.includes("OPERATION") ||  i.code.includes("ADMIN") 
                          ) && 
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="ticket_access"
                                    checked={values.ticket_access}
                                    color="primary"
                                  />
                                }
                                onChange={handleChange}
                                label="Required support ticket assign access"
                                name="ticket_access"
                                id="price-update"
                                value={values.ticket_access}
                              />
                              </>}
                            
                          {values.partner && (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="requiredApi"
                                    checked={values.requiredApi}
                                    color="primary"
                                  />
                                }
                                onChange={handleChange}
                                label="Required api"
                                name="requiredApi"
                                id="price-update"
                                value={values.requiredApi}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="visibleAllScrips"
                                    checked={values.visibleAllScrips}
                                    color="primary"
                                  />
                                }
                                onChange={handleChange}
                                label="Visible all scrips"
                                name="visibleAllScrips"
                                id="price-update"
                                value={values.visibleAllScrips}
                              />
                            </>
                          )}
                        </Grid>
                        {values.requiredApi && values.partner && (
                          <>
                            <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                              {/* <Button
                                  color="primary"
                                  // className={accountBtn}
                                  size="medium"
                                  disabled={values.userName}
                                  variant="outlined"
                                  onClick={() => {
                                    setFieldValue('apiKey', true)
                                    generateApiKey().then((res) => {
                                      console.log(res)
                                      setFieldValue('userName', res.userName)
                                      setFieldValue('password', res.password)
                                      setFieldValue('endpoint', res.endpoint)
                                    })
                                  }}
                                >Generate api key</Button> */}
                              <FormLabel component="legend">
                                Api key type
                              </FormLabel>
                              <RadioGroup
                                aria-label="quiz"
                                name="quiz"
                                value={values.apiKey}
                                onChange={(e) => {
                                  setFieldValue("apiKey", e.target.value);
                                  if (
                                    e.target.value === "credentials" &&
                                    values.userName === ""
                                  ) {
                                    generateApiKey().then((res) => {
                                      setFieldValue("userName", res.userName);
                                      setFieldValue("password", res.password);
                                      setFieldValue("endpoint", res.endpoint);
                                    });
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="token"
                                  control={<Radio />}
                                  label="Token"
                                />
                                <FormControlLabel
                                  value="credentials"
                                  control={<Radio />}
                                  label="Auth Credentials"
                                />
                              </RadioGroup>
                              <FormHelperText style={{ color: "red" }}>
                                {touched.apiKey && errors.apiKey}
                              </FormHelperText>
                              {values.apiKey === "credentials" && (
                                <div style={{ marginTop: "1rem" }}>
                                  <Typography>
                                    <b>Username : </b>
                                    {values.userName}
                                  </Typography>
                                  <Typography>
                                    <b>Password : </b>
                                    {values.password}
                                  </Typography>
                                  <Typography>
                                    <b>EndPoint : </b>
                                    {values.endpoint
                                      ? values.endpoint
                                      : "https://api.unlistedkart.com/api"}
                                  </Typography>
                                </div>
                              )}
                            </Grid>
                          </>
                        )}
                        {values.login && (
                          <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                            <PhoneInput
                              id="mobile"
                              name="loginPhone"
                              onlyCountries={[
                                "in",
                                "us",
                                "au",
                                "sg",
                                "hk",
                                "ae",
                                "gb",
                                "nz",
                                "ph",
                                "de"
                              ]}
                              countryCodeEditable={false}
                              country={"in"}
                              style={
                                valueColor
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {
                                      border: "1px solid #314B8F",
                                      borderRadius: "5px",
                                    }
                              }
                              placeholder="Enter phone number"
                              value={values.loginPhone}
                              handleBlur={handleBlur}
                              onChange={(e, data) => {
                                handleError(e);
                                setFieldValue("loginPhone", e);
                                setCountryCode(data.countryCode);
                              }}
                            />
                            <FormHelperText style={{ color: "red" }}>
                              {errors.loginPhone &&
                                touched.loginPhone &&
                                errors.loginPhone}
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={5} py={2}>
                        {values.selectrole.some(
                          (i) =>
                            i.code.includes("CHANNEL_PARTNER") ||
                            i.code.includes("INVESTER_CLIENT") ||
                            i.code.includes("PARTNER_CLIENT") ||
                            i.code.includes("PARTNER_ADMIN") ||
                            i.code.includes("PARTNER_RELATIONSHIP_MANAGER")
                        ) && (
                          <Grid item xs={12} sm={6} md={4} py={2} lg={4}>
                            <Autocomplete
                              id="internal-representative"
                              multiple
                              // options={representatives}
                              options={representatives?.filter(function(data) {
                                return (
                                  values.internalrepresentative?.filter(
                                    function(item) {
                                      return item.id === data.id;
                                    }
                                  ).length === 0
                                );
                              })}
                              getOptionLabel={(option) => option.label || ""}
                              value={values.internalrepresentative}
                              onChange={(e, value) => {
                                setFieldValue("internalrepresentative", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.internalrepresentative &&
                                      errors.internalrepresentative
                                  )}
                                  helperText={
                                    touched.internalrepresentative &&
                                    errors.internalrepresentative
                                  }
                                  name="internalrepresentative"
                                  label="Internal representative"
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {(values.selectrole.some(
                          (i) =>
                            i.code.includes("PARTNER_CLIENT") ||
                            i.code.includes("PARTNER_RELATIONSHIP_MANAGER")
                        ) ||
                          (values.requiredCoBranding && values.investor)) && (
                          <Grid item xs={12} sm={6} md={4} py={2} lg={4}>
                            <Autocomplete
                              id="internal-representative"
                              options={partners}
                              getOptionLabel={(option) => option.label || ""}
                              value={values.partnerClientCode}
                              onChange={(e, value) => {
                                setFieldValue("partnerClientCode", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.partnerClientCode &&
                                      errors.partnerClientCode
                                  )}
                                  helperText={
                                    touched.partnerClientCode &&
                                    errors.partnerClientCode
                                  }
                                  name="partnerClientCode"
                                  label="Partner admin"
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {!values.visibleAllScrips &&
                          values.selectrole.some((i) =>
                            i.code.includes("PARTNER_ADMIN")
                          ) && (
                            <Grid item xs={12} sm={6} md={2} py={2} lg={2}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setOpen(true);
                                }}
                                variant="outlined"
                              >
                                Config Scrips
                              </Button>
                            </Grid>
                          )}
                        {values.selectrole.some((i) =>
                          i.code.includes("PARTNER_ADMIN")
                        ) && (
                          <>
                            <Grid item xs={12} sm={6} md={2} py={2} lg={2}>
                              <TextField
                                label="Max Sell Percentage"
                                id="first-name"
                                type="number"
                                name="maxSellPercentage"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.maxSellPercentage &&
                                    errors.maxSellPercentage
                                )}
                                fullWidth
                                helperText={
                                  touched.maxSellPercentage &&
                                  errors.maxSellPercentage
                                }
                                value={values.maxSellPercentage}
                                variant="standard"
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2} py={2} lg={2}>
                              <TextField
                                label="Partner landing percentage"
                                id="first-name"
                                type="number"
                                name="partnerLandingPercentage"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.partnerLandingPercentage &&
                                    errors.partnerLandingPercentage
                                )}
                                fullWidth
                                helperText={
                                  touched.partnerLandingPercentage &&
                                  errors.partnerLandingPercentage
                                }
                                value={values.partnerLandingPercentage}
                                variant="standard"
                                autoComplete="off"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {/* <div style={{ padding: theme.spacing(2) }}> */}
                      <Grid
                        container
                        spacing={5}
                        alignItems="center"
                        py={2}
                        style={{ paddingBottom: "1rem" }}
                      >
                        {values.partner && (
                          <>
                            <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                              <TextField
                                disabled={partnerCode}
                                label="Partner Code*"
                                id="first-name"
                                name="partnerCode"
                                maxLength={4}
                                InputProps={{
                                  endAdornment: codeExists === "new" && (
                                    <DoneIcon style={{ color: "green" }} />
                                  ),
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(
                                    "partnerCode",
                                    e.target.value?.toUpperCase()
                                  );
                                  if (e.target.value?.length === 12) {
                                    CheckPartnerCode(
                                      e.target.value.toUpperCase()
                                    );
                                  }
                                  setCodeExists(false);
                                }}
                                inputProps={{ maxLength: 12 }}
                                error={Boolean(
                                  (touched.partnerCode && errors.partnerCode) ||
                                    codeExists === "old"
                                )}
                                fullWidth
                                helperText={
                                  codeExists === "old"
                                    ? "Code already Exists"
                                    : touched.partnerCode && errors.partnerCode
                                }
                                value={values.partnerCode}
                                variant="standard"
                                autoComplete="off"
                              />
                              {/* <ErrorFocus /> */}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              py={2}
                              lg={3}
                              className={classes.gridPadding}
                            >
                              <div>
                                <Typography className={classes.label}>
                                  Partner Logo
                                </Typography>
                                <OutlinedInput
                                  accept="image/*"
                                  // className={classes.input}
                                  id="icon-button-file"
                                  name="partnerLogo"
                                  fullWidth={true}
                                  onChange={(e) => {
                                    let file = e.currentTarget.files[0];
                                    setFieldValue(
                                      "partnerLogo",
                                      e.currentTarget.files[0]
                                    );
                                    const url =
                                      file && URL.createObjectURL(file);
                                    setImageUrl(url);
                                  }}
                                  type="file"
                                />
                                <FormHelperText
                                  style={{ color: "#F44336", paddingLeft: 16 }}
                                >
                                  {touched.partnerLogo && errors.partnerLogo}
                                </FormHelperText>
                              </div>
                              <ErrorFocus />
                            </Grid>
                            {imageUrl && (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                py={2}
                                lg={3}
                                className={classes.gridPadding}
                                style={{ textAlign: "center" }}
                              >
                                <Typography
                                  className={classes.removeLink}
                                  variant="body1"
                                >
                                  Logo Preview
                                </Typography>
                                <img
                                  src={imageUrl}
                                  width="150px"
                                  alt="partnerLogo"
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                      {/* </div> */}
                    </>
                  )}
                  {/* <div style={{ padding: theme.spacing(2) }}> */}
                  <Grid container spacing={5} alignItems="center">
                  <Grid item xs={3} sm={6} md={4} py={2} lg={4}>
                          <Autocomplete
                            id="status"
                            options={status}
                            value={values.status}
                            onChange={(e, value) => {
                              setFieldValue("status", value);
                              if(value !=='Rejected'){
                                setFieldValue("rejectReason",'');
                              }
                            }}
                            disabled ={role !== 'ADMIN' && role !== 'OPERATION'}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.status && errors.status
                                )}
                                helperText={
                                  touched.status && errors.status
                                }
                                name="status"
                                label="Status"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                          {/* <ErrorFocus /> */}
                        </Grid>
                        {
                          values.status ==="Rejected" ? 
                          <Grid item xs={3} sm={6} md={4} py={2} lg={4}>
                              <TextField
                                name="rejectReason"
                                label="Reject Reason"
                                onChange={handleChange}
                                fullWidth
                                value={values.rejectReason}
                                variant="standard"
                                error={Boolean(
                                  touched.rejectReason && errors.rejectReason
                                )}
                                helperText={
                                  touched.rejectReason && errors.rejectReason
                                }
                              />
                          {/* <ErrorFocus /> */}
                        </Grid>
                          :null
                        }
                        <Grid item xs={3} sm={6} md={2} py={2} lg={2}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                name="prepaidApproved"
                                checked={values.prepaidApproved}
                                color="primary"
                              />
                            }
                            disabled ={role !== 'ADMIN' && role !== 'OPERATION'}
                            onChange={(e)=>{
                              if (e.target.checked) {
                                setFieldValue(
                                  "prepaidApproved",
                                  e.target.checked
                                );
                              } else {
                                setFieldValue("prepaidApprovedRange", 0);
                                setFieldValue(
                                  "prepaidApproved",
                                  e.target.checked
                                );
                              }
                            }}
                            label="Prepaid Approved"
                            name="prepaidApproved"
                            id="login-mob"
                            value={values.prepaidApproved}
                          />
                        </Grid>
                        {
                           values.prepaidApproved ? <Grid item xs={3} sm={6} md={2} py={2} lg={2}>
                            <TextField
                            label="Prepaid approved Range*"
                            id="first-name"
                            type='number'
                            disabled ={role !== 'ADMIN' && role !== 'OPERATION'}
                            // aria-describedby="my-helper-text"
                            name="prepaidApprovedRange"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.prepaidApprovedRange && errors.prepaidApprovedRange
                            )}
                            fullWidth
                            helperText={touched.prepaidApprovedRange && errors.prepaidApprovedRange}
                            value={values.prepaidApprovedRange}
                            variant="standard"
                            autoComplete="off"
                          />
                            </Grid>: null
                        }
                        </Grid>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl component="fieldset" color="primary">
                        <RadioGroup
                          aria-label="gender"
                          name="userType"
                          color="primary"
                          value={values.userType}
                          // error={Boolean(
                          //   touched.userType && errors.userType
                          // )}
                          // helperText={touched.userType && errors.userType}
                          onChange={(e,value)=>{
                            setFieldValue('userType',value)
                            if(value ==='INDIVIDUAL'){
                              setFieldValue('stackHolderReport',[])
                            }
                          }}
                          // inputProps={{ autoFocus: true }}
                        >
                          <div>
                            <FormControlLabel
                              value={"INDIVIDUAL"}
                              color="primary"
                              control={<Radio color="primary" />}
                              label={"Individual"}
                            />
                            <FormControlLabel
                              value={"COMPANY"}
                              color="primary"
                              control={<Radio color="primary" />}
                              label={"Company"}
                            />
                          </div>
                        </RadioGroup>
                        <FormHelperText style={{ color: "#F44336" }}>
                          {errors.userType &&
                            touched.userType &&
                            errors.userType}
                        </FormHelperText>
                      </FormControl>
                      {/* <ErrorFocus/> */}
                    </Grid>
                  </Grid>
                  {/* </div> */}

                  {values.userType === "INDIVIDUAL" && (
                    <React.Fragment>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3} p={1}>
                          <TextField
                            label="First Name*"
                            id="first-name"
                            // aria-describedby="my-helper-text"
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "firstName",
                                capitalizeFirstLetterForName(e.target.value)
                              )
                            }
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            value={values.firstName}
                            variant="standard"
                            autoComplete="off"
                          />
                          {/* <ErrorFocus /> */}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            fullWidth
                            label="Last Name"
                            // id="last-name"
                            name="lastname"
                            type="lastname"
                            value={values.lastname}
                            onChange={(e) =>
                              setFieldValue(
                                "lastname",
                                capitalizeFirstLetterForName(e.target.value)
                              )
                            }
                            variant="standard"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Email"
                            id="email"
                            name="email"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "email",
                                e.target.value?.toLowerCase()
                              )
                            }                           
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            variant="standard"
                            value={values.email}
                            // value={values.email}
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Phone"
                            id="phone"
                            name="phone"
                            fullWidth
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                            variant="standard"
                            inputProps={{ maxLength: 16 }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^+0-9]/g,
                                ""
                              );
                            }}
                            value={values.phone}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Pan*"
                            id="pan"
                            name="pan"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "pan",
                                e.target.value?.toUpperCase()
                              )
                            }
                            variant="standard"
                            error={Boolean(touched.pan && errors.pan)}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            autoComplete="off"
                            helperText={touched.pan && errors.pan}
                            value={values.pan}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  {values.userType === "COMPANY" && (
                    <React.Fragment>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Company Name*"
                            id="company-name"
                            name="companyname"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "companyname",
                                capitalizeFirstLetterForName(e.target.value)
                              )
                            }
                            error={Boolean(
                              touched.companyname && errors.companyname
                            )}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            helperText={
                              touched.companyname && errors.companyname
                            }
                            value={values.companyname}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            fullWidth
                            label="Company Registered No"
                            id="company-registered"
                            name="companyregistered"
                            value={values.companyregistered}
                            autoComplete="off"
                            onChange={handleChange}
                            // error={Boolean(
                            //   touched.companyregistered &&
                            //     errors.companyregistered
                            // )}
                            // helperText={
                            //   touched.companyregistered &&
                            //   errors.companyregistered
                            // }
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="GSTIN"
                            id="gstin"
                            name="gstin"
                            autoComplete="off"
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ maxLength: 15 }}
                            error={Boolean(touched.gstin && errors.gstin)}
                            fullWidth
                            helperText={touched.gstin && errors.gstin}
                            value={values.gstin?.toUpperCase()}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Business Nature"
                            id="businessNature"
                            name="businessNature"
                            autoComplete="off"
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            value={values.businessNature}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Email*"
                            id="email"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            value={values.email}
                            variant="standard"
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Phone*"
                            id="phone"
                            name="phone"
                            fullWidth
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                            inputProps={{ maxLength: 16 }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^+0-9]/g,
                                ""
                              );
                            }}
                            variant="standard"
                            value={values.phone}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Pan*"
                            id="pan"
                            name="pan"
                            autoComplete="off"
                            variant="standard"
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) =>
                              setFieldValue(
                                "pan",
                                e.target.value?.toUpperCase()
                              )
                            }
                            error={Boolean(touched.pan && errors.pan)}
                            fullWidth
                            helperText={touched.pan && errors.pan}
                            value={values.pan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                          <TextField
                            label="Authorised Person"
                            id="authorisedPerson"
                            name="authorisedPerson"
                            autoComplete="off"
                            value={values.authorisedPerson}
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </NormalCard>
              <NormalCard title="Authorised Personnel (If Required)">
              <div style={{ padding: "1rem" }}>
              <FieldArray
              name="stackHolderReport">
              {({ push, remove }) => (
                <div >
                  {values.stackHolderReport.map((_, index) => {
                    const touchedDesignation = getIn(touched, `stackHolderReport.${index}.designation`);
                    const errorDesignation = getIn(errors, `stackHolderReport.${index}.designation`);
                     const touchedInvestorName = getIn(touched, `stackHolderReport.${index}.stackHolder`);
                     const errorInvestorName = getIn(errors, `stackHolderReport.${index}.stackHolder`);
                     const touchedInvestorPercentage = getIn(touched, `stackHolderReport.${index}.stackHoldPercentage`);
                     const errorInvestorPercentage = getIn(errors, `stackHolderReport.${index}.stackHoldPercentage`);
                     const touchedInvestorPan = getIn(touched, `stackHolderReport.${index}.stackHolderPAN`);
                     const errorInvestorPan = getIn(errors, `stackHolderReport.${index}.stackHolderPAN`);
                     const touchedAddress = getIn(touched, `stackHolderReport.${index}.address`);
                     const errorAddress = getIn(errors, `stackHolderReport.${index}.address`);
                     const touchedRelationName = getIn(touched, `stackHolderReport.${index}.relationName`);
                     const errorRelationName = getIn(errors, `stackHolderReport.${index}.relationName`);
                    return(
                      (
                        <div key={index}>
                          {/** both these conventions do the same */}
                          <Grid container columnSpacing={2} rowSpacing={5} alignItems="center" key={index}>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          className={classes.gridPadding}
                        >
                        <FormControl variant="standard" style={{ width: "100%" }} error={touchedDesignation && errorDesignation}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Designation
                        </InputLabel>
                        <Select
                          id="designation"
                          name={`stackHolderReport.${index}.designation`}
                          value={values.stackHolderReport[index].designation}
                          onChange={handleChange}
                        >
                          {['Director','Secretary','Authorised Signatory','Karta','Share Holder'].map((data) => (
                            <MenuItem value={data} key={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{color:'rgb(211, 47, 47)'}}>{touchedDesignation && errorDesignation}</FormHelperText>
                        </FormControl>
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          lg={2}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label="Name*"
                           id="investor_key_name"
                           autoComplete="off"
                           fullWidth
                           variant="standard"
                           name={`stackHolderReport.${index}.stackHolder`}
                           value={values.stackHolderReport[index].stackHolder}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean( touchedInvestorName && errorInvestorName)}
                            helperText={ touchedInvestorName && errorInvestorName}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label={`Stake %${values.stackHolderReport[index].designation ==="Authorised Signatory" ? '' : '*'}`}
                           variant="standard"
                           id="stackHoldPercentage"
                           name={`stackHolderReport.${index}.stackHoldPercentage`}
                           value={values.stackHolderReport[index].stackHoldPercentage}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
                           onBlur={handleBlur}
                           error={Boolean(touchedInvestorPercentage && errorInvestorPercentage)}
                           helperText={touchedInvestorPercentage && errorInvestorPercentage}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          className={classes.gridPadding}
                        >
                          <TextField
                           variant="standard"
                           id="stackHolderPAN"
                           name={`stackHolderReport.${index}.stackHolderPAN`}
                           label={`PAN${values.stackHolderReport[index].designation ==="Authorised Signatory" ? '' : '*'}`}
                           value={values.stackHolderReport[index].stackHolderPAN}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
                           onBlur={handleBlur}
                           error={Boolean(touchedInvestorPan && errorInvestorPan)}
                           helperText={touchedInvestorPan && errorInvestorPan}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          lg={1}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label="Email"
                           variant="standard"
                           id="stackHolderEmail"
                           name={`stackHolderReport.${index}.stackHolderEmail`}
                           value={values.stackHolderReport[index].stackHolderEmail}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
                           onBlur={handleBlur}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}  lg={2}>
                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <FormControl variant="standard"  sx={{ minWidth: 78 }}>
                            <InputLabel>Relation</InputLabel>
                            <Select
                              name={`stackHolderReport.${index}.relation`}
                              value={values.stackHolderReport[index].relation}
                              onChange={handleChange}
                              label="Relation"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {['C/O','D/O' ,'S/O','W/O','H/O'].map((item) => (
                                <MenuItem key={item} value={item}>
                                ({item})
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Name"
                            variant='standard'
                            name={`stackHolderReport.${index}.relationName`}
                            value={values.stackHolderReport[index].relationName}
                            onChange={handleChange}
                            sx={{ flex: 1 }}
                            error={Boolean(touchedRelationName && errorRelationName)}
                            helperText={touchedRelationName && errorRelationName}
                          />
                        </Box>
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label={`Address${values.stackHolderReport[index].designation ==="Authorised Signatory" ? '' : '*'}`}
                           variant="standard"
                           id="address"
                           name={`stackHolderReport.${index}.address`}
                           value={values.stackHolderReport[index].address}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
                           onBlur={handleBlur}
                           error={Boolean(touchedAddress && errorAddress)}
                           helperText={touchedAddress && errorAddress}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          className={classes.gridPadding}
                         
                        >
                        <CloseIcon   onClick={() => {remove(index)}} style={{marginTop:"1.5rem"}}  />
                          </Grid>
                          </Grid>
                            </div>
                          )
                    )
                  })}
                        <Button
                      variant="contained"
                      color="primary"
                      style={{margin:'2rem 0'}}
                      onClick={() => {
                          push({
                            designation:"",
                            stackHolder:"",
                            stackHoldPercentage:"",
                            stackHolderPAN:"",
                            stackHolderEmail:"",
                            relation:"",
                            relationName:"",  
                            address:""
                          })
                      }}
                   >
                    Add Stake Holder
                   </Button>
                      </div>
                    )}
                  </FieldArray>
                      </div>
              </NormalCard>  

              <NormalCard title="Registered Address">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div className={classes.titleContainer}>
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ paddingTop: "1rem" }}
                  >
          {values.userType === "COMPANY" ? null :     <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormControl variant="standard"  sx={{ minWidth: 100 }}>
              <InputLabel>Relation</InputLabel>
              <Select
                value={values.relation}
                name="relation"
                onChange={(e)=>{
                  let value= e.target.value
                  if(value){
                    setFieldValue('relation',e.target.value)
                  }else{
                    setFieldValue('relation','')
                    setFieldValue('relationName','')
                  }
                }}
                label="Relation"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {['C/O','D/O' ,'S/O','W/O','H/O'].map((item) => (
                  <MenuItem key={item} value={item}>
                  ({item})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Name"
              name="relationName"
              variant='standard'
              value={values.relationName}
              onChange={handleChange}
              sx={{ flex: 1 }}
              error={Boolean(touched.relationName && errors.relationName)}
              helperText={touched.relationName && errors.relationName}
            />
          </Box>
          </Grid>}
    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <TextField
                        id="address-line1"
                        label="Address Line 1"
                        name="addresslineone"
                        autoComplete="off"
                        fullWidth={true}
                        variant="standard"
                        onChange={handleChange}
                        value={values.addresslineone}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <TextField
                        id="address-line2"
                        label="Address Line 2"
                        name="addresslinetwo"
                        autoComplete="off"
                        fullWidth={true}
                        onChange={handleChange}
                        variant="standard"
                        value={values.addresslinetwo}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <TextField
                        id="city"
                        label="City"
                        name="city"
                        autoComplete="off"
                        fullWidth={true}
                        variant="standard"
                        value={values.city}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          State
                        </InputLabel>
                        <Select
                          id="state"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                        >
                          {states.map((data) => (
                            <MenuItem value={data.code} key={data.code}>
                              {data.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <TextField
                        id="postal-code"
                        name="postcode"
                        label="Postal Code"
                        variant="standard"
                        fullWidth={true}
                        autoComplete="off"
                        value={values.postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.postcode && errors.postcode)}
                        helperText={touched.postcode && errors.postcode}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} py={2} lg={3}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Country
                        </InputLabel>
                        <Select
                          id="country"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                           <MenuItem value="">
                              <em>None</em>
                           </MenuItem>
                          {country.map((data) => (
                            <MenuItem value={data.code} key={data.code}>
                              {data.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              <div className={classes.btnroot}>
                <div style={{ textAlign: "end" }} className={classes.wrapper}>
                  <Button
                    color="primary"
                    // className={accountBtn}
                    size="medium"
                    disabled={
                      loading ||
                      (mode === "create" && userID !== null) ||
                      isSubmitting
                    }
                    type="submit"
                    variant="contained"
                    onClick={() => handleError(values.loginPhone)}
                  >
                    {loading === false ? (
                      <> {mode === "edit" ? "UPDATE" : "Create"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        {mode === "edit" ? "UPDATE" : "Create"}{" "}
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>

        {role === "INVESTER_CLIENT" ? (
          <></>
        ) : (
            <ContactInfo
              userID={userID}
              type="button"
              edit={true}
            />
        )}
        <DematAccountData userID={userID} type={userType} edit={true} />
        <BankAccountData userID={userID} edit={true} />
        <KycDocumentData
          userID={userID}
          edit={true}
          userName={userName}
          requestId={requestId}
        />

        <Documents data={userID} edit={true} />
      </Page>

   
      <ModalCentered
        title={"Configure scrips"}
        open={open}
        toggle={onClose}
        children={
          <ConfigurePartnerScrips
            formSubmit={handlePartnerVisibleScrips}
            data={scripIds}
          />
        }
      />
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, null)(CreateUser);
